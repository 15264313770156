import * as React from "react"
import { Link } from "gatsby"
import "../../css/_section-audit.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { RecCard } from "../../components/resources"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/resources/",
    label: "Resources",
  },
]

const blogs = [
  {
    hrf: "https://sp1.sdcdn.app/pdf/Vigilant_Arctic-Wolf_Battlecard.pdf",
    rec: "../images/resource/battlecard_cardimage.jpg",
    subHeader: "Battlecard",
    title: "Vigilant vs. Artic Wolf",
    content: "Learn how Vigilant outperforms Arctic Wolf.",
  },

  {
    hrf: "https://sp1.sdcdn.app/pdf/Vigilant_Esentire_Battlecard.pdf",
    rec: "../images/resource/battlecard_cardimage.jpg",
    subHeader: "Battlecard",
    title: "Vigilant vs eSentire",
    content: "Learn how Vigilant outperforms eSentire.",
  },
]
const BattleCards = () => {
  return (
    <Layout>
      <Seo title="Security Threat Audit" />
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="grid--bg--for-it-ops-3a">
      <section className="section-title-top">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>RESOURCES</h1>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
      <section className="section-client-cards">
        <div className="container">
        <div className="row pb-5 blog--nav">
            <div className="col-sm-2 ">
              <Link to="/resources/" activeClassName="blog--nav-active">ALL RESOURCES</Link>
            </div>
            <div className="col-sm-2">
              <Link to="/resources/solution-briefs" activeClassName="blog--nav-active">SOLUTION BRIEFS</Link>
            </div>
            <div className="col-sm-2">
              <Link to="/resources/success-stories" activeClassName="blog--nav-active">SUCCESS STORIES</Link>
            </div>
            <div className="col-sm-2">
              <Link to="/resources/battlecards" activeClassName="blog--nav-active">BATTLECARDS</Link>
            </div>
          </div>
          <div className="row">
            <ul className="client-cards-lists">
              {blogs.map(blog => {
                return (
                  <RecCard
                    imgSrc={blog.rec}
                    subHeader={blog.subHeader}
                    title={blog.title}
                    content={blog.content}
                    hrf={blog.hrf}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </section>
      </div>
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}
export default BattleCards
